/* body {
    display: block;
    font-family: 'Lexend';
}

body,
p {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
} */


body {
    font-size: 1rem;
    font-family: "Lexend";
    font-weight: initial;
    line-height: normal;
    -webkit-font-smoothing: antialiased;
}

.container {
    padding: 10px;
}

.button {
    text-align: center;
    float: right;
}

.btnPreview {
    width: 70px;
    height: 30px;
    background-color: #333333;
    color: #fff;
    margin: 0 auto;
    padding: 0;
    line-height: 30px;
    text-align: center;
    text-decoration: none;
    float: right;
    border-radius: 10px;
    margin-right: 10px;
    font-size: small;
}

.btnPreview:hover {
    color: #fff;
    text-decoration: none;
}

#pad {
    /* background-color: #fff; */
    color: #000;
    margin: 0;
    padding: 0;
    font-size: 100%;
    width: 100%;
}

.nopadding {
    padding: 0rem;
}

.writer {
    top: 6em;
    padding-top: 0.25em;
    background-color: #fff;
    color: #000;
    line-height: 1.4;
    padding-left: 30%;
    padding-right: 30%;
    position: fixed !important;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    /* height: auto; */
    height: calc(100% - 3em - 1px);
    /* padding: 1em 2em 2em; */
    font-size: 1.2em;
    letter-spacing: .6px;
    box-sizing: border-box;
    resize: none;
    border: 0;
    outline: 0;
    font-family: 'Lexend';
}

.footer {
    position: absolute;
    bottom: 10;
    margin: 0;
    /* left: calc(100%/2 - 7%); */
    text-align: center;
}

.rc-md-editor .editor-container .sec-md .input {
    /* top: 6em; */
    padding-top: 0.25em;
    background-color: #fff;
    color: #000;
    line-height: 1.4;
    /* padding-left: 30%;
    padding-right: 30%; */
    /* position: fixed !important; */
    /* right: 0;
    bottom: 0;
    left: 0;
    width: 100%; */
    /* height: auto; */
    /* height: calc(100% - 3em - 1px); */
    /* padding: 1em 2em 2em; */
    font-size: 14px;
    letter-spacing: .6px;
    box-sizing: border-box;
    resize: none;
    border: 0;
    outline: 0;
    font-family: 'Lexend';
}

.custom-html-style {
    color: #000;
    /* line-height: 1.4; */
}

.rc-md-editor .rc-md-navigation {
    background: #fff;
}

.status {
    text-align: center;
    float: left;
    font-weight: bold;
    margin-left: 44px;
    margin-top: 22px;
    /* color: rgb(90, 250, 148) */
}
.statusWarning {
    text-align: center;
    float: right;
    font-weight: bold;
    margin-right:10px;
    margin-top: 22px;
    text-decoration: underline;
    text-decoration-color: #007bff;
    /* color: rgb(90, 250, 148) */
}


.setting-button {
    float: right
}

.notebottom {
    font-size: small;
    color: #b7b7b7
}

.toastui-editor-contents{
    font-family: 'Lexend'!important;
}

.popup-content {
    text-align: center;
    padding: 20px;
    width: 50%;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .input:focus{
    border: 1px solid #ccc;
  }
  
  .buttons {
    margin-top: 15px;
  }
  
  .submit-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background-color: #0056b3;
  }