.nameprofile {
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    line-height: 40px;
    text-align: center;
    border-radius: 20px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #000;
    font-size: larger;
    padding-left: 7px;
    padding-bottom: 5px;
}


.loading {
    position: absolute;
    left: 45%;
    top: 40%;
}

.form-check {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 0;
}

.form-check .form-check-label {
    min-height: 18px;
    display: block;
    margin-left: 1.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
}